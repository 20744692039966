import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UNAUTHORIZED } from '../auth/auth-errors';
import { AuthService } from '../auth/auth.service';
import { Guid } from '../guid';
import { LibraryService } from '../library/library.service';
import { PaymentOptions } from '../shopping-cart/payment-options';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';
import { CreditCard } from './credit-card';
import { Order } from './order';
import { PaymentAccountWithCvv } from './payment-account-with-cvv';
import { PaymentRequest } from './payment-request';
import { PaymentResponse } from './payment-response';
import { PaymentStatus } from './payment-status';
import { TokenResponse } from './token-response';
import { TokenizedCardData } from './tokenized-card-data';
import { Cookie } from '../cookie';
import Cookies from 'js-cookie';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private readonly orderSource: Subject<Order> = new Subject();

    constructor(
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly shoppingCart: ShoppingCartService,
        private readonly libraryService: LibraryService
    ) {}

    public get completedOrders(): Observable<Order> {
        return this.orderSource.asObservable();
    }

    public notifyPaymentCompleted(order: Order) {
        this.orderSource.next(order);
    }

    public async validatePayment(orderId: string): Promise<PaymentStatus> {
        const url = `${environment.api.host}/payment/validate`;
        const response = await lastValueFrom(
            this.http.get<{ status: PaymentStatus }>(url, {
                params: {
                    orderId
                }
            })
        );

        return response.status;
    }

    public async getCreditCardToken(card: CreditCard): Promise<TokenResponse> {
        if (!this.authService.userSnapshot) {
            throw new Error(UNAUTHORIZED);
        }

        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState === 4) {
                    if (request.status === 201) {
                        resolve(JSON.parse(request.response));
                    } else {
                        reject(request.response);
                    }
                }
            };

            const id = Guid.newGuid();
            const route = `${environment.centrobill.host}/tokenize`;
            request.open('POST', route);
            request.setRequestHeader('X-Request-ID', id);
            request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            request.send(JSON.stringify(card));

            return request.response;
        });
    }

    public async payWithPaymentAccount(
        account: PaymentAccountWithCvv,
        options?: PaymentOptions
    ): Promise<PaymentResponse> {
        if (options) {
            // Disable payment storage as cvv accounts are already stored.
            options.savePayment = false;
        }

        return this.pay(account, options);
    }

    public async payWithTokenizedCardData(
        data: TokenizedCardData,
        options?: PaymentOptions
    ): Promise<PaymentResponse> {
        return this.pay(data, options);
    }

    private async pay(
        source: TokenizedCardData | PaymentAccountWithCvv,
        options?: PaymentOptions
    ): Promise<PaymentResponse> {
        if (!this.authService.userSnapshot) {
            throw new Error(UNAUTHORIZED);
        }

        if (!options) {
            options = {
                savePayment: false
            };
        }

        const url = `${environment.api.host}/payment`;
        const payload: PaymentRequest = {
            paymentSource: source,
            customer: {
                id: this.authService.userSnapshot?.account.PlayFabId as string,
                name: this.authService.userSnapshot?.account.Username as string,
                email: this.authService.userSnapshot?.account.PrivateInfo?.Email as string
            },
            lineItems: this.shoppingCart.itemsSnapshot
                .map(x => x.id)
                .filter(
                    x => !this.libraryService.userSnapshot.Inventory.map(x => x.ItemId).includes(x)
                ),
            system: {
                userAgent: navigator.userAgent,
                browserLanguage: navigator.language,
                browserColorDepth: screen.colorDepth,
                browserJavaEnabled: false,
                browserScreenHeight: screen.height,
                browserScreenWidth: screen.width,
                browserTimezone: `${new Date().getTimezoneOffset()}`
            },
            savePayment: options.savePayment || false,
            voucherCode: options.voucherCode
        };

        const rtkcid = Cookies.get(Cookie.redTrackStore) || 'xxxxxxxxxxxxxxxxxxxxxxxx';
        return lastValueFrom(
            this.http.post<any>(url, payload, {
                headers: {
                    'x-rtkcid': rtkcid
                }
            })
        );
    }
}
